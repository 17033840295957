.progress {
  display: flex;
  gap: 5px;
  margin-bottom: 15px;
}

.svg {
  transform: rotate(180deg);
}

.circleBg {
  fill: none;
  stroke: #e6e6e6;
}

.circle {
  fill: none;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(0.25turn);
  transform-origin: 50% 50%;

  &.red {
    stroke: #f62f1c;
  }

  &.yellow {
    stroke: #ffbf3d;
  }

  &.green {
    stroke: #6ad22a;
  }
}

.text {
  color: var(--UIMain-Gray, #8b8b8b);

  font-family: var(--font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}
