.review_product {
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: var(--font);
  color: var(--text-color);
  min-height: 90px;
}

.image {
  flex: 1;
  flex-shrink: 0;

  .inner {
    width: 100%;
    //padding-bottom: 64%;
    //position: relative;

    img {
      width: 100%;
      height: auto;
    }

    //img {
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  width: 100%;
    //  height: 100%;
    //  object-fit: contain;
    //}
  }
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .name {
    color: var(--Black, #000);
    leading-trim: both;
    text-edge: cap;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 108.333%;

    &.small {
      font-size: 20px;
    }
  }
}

.original {
  position: relative;
  width: fit-content;

  .original_main {
    display: flex;
    gap: 4px;
    align-items: center;

    color: #58c94e;

    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .help {
    margin-top: 1.5px;

    display: flex;
    gap: 4px;
    align-items: center;

    color: var(--Text-black, #222);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; /* 100% */
  }
}
