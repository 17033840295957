.stars_input {
  display: flex;
  align-items: center;
}

.star {
  color: #dedddd;
  padding: 0 5px;

  transition: color 0.2s;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.on {
    color: #ffcf54;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}
