.review_text_input {
  margin: 20px 0;
  padding-top: 10px;
  font-family: var(--font);
}

.input_container {
  display: block;
  position: relative;
}

.label {
  position: absolute;
  left: 0;
  top: 10px;
  transform: translateY(-50%);
  transition: all 0.2s ease;
  pointer-events: none;
  color: var(--UIMain-Gray, #8b8b8b);

  &.focus {
    top: -5px; // -10px;
    left: 0;
    font-size: 12px;
    line-height: 17px;
    color: var(--UIMain-Gray, #8b8b8b);
  }
}

.input {
  font-family: var(--font);

  width: 100%;
  padding: 5px 0;
  border: none;
  border-bottom: 2px solid var(--UIMain-Outline, #e0e0e0);
  outline: none;
  transition: border-color 0.2s ease;
  border-radius: unset;

  font-size: 16px;
  resize: none;

  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    border-color: #333;
  }
}

.description {
  font-size: 12px;
  line-height: 15px;
  color: var(--UIMain-Gray, #555);
}
