.review_image_array_input {
}

.button {
  width: 100%;
  padding: 14px;
  border-radius: 10px;
  background: var(--UIMain-Gray-Background, #f2f2f2);

  color: var(--UIMain-Black, #000);
  /* p16-med */
  font-family: var(--font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */

  border: unset;
  outline: unset;

  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}

.imageUploader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fileInput {
  display: none;
}

.addButton {
  margin-bottom: 20px;
}

.imageList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  margin-bottom: 20px;
}

.imageWrapper {
  position: relative;
  width: 70px;
  height: 70px;

  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &.error {
    border: 1px solid rgba(255, 0, 0, 0.5);
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 10px;
  overflow: hidden;

  position: relative;
  z-index: 2;

  &.loading {
    opacity: 0.2;
  }
}

.progressBar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1;

  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-bottom-color: transparent;
  border-radius: 50%;

  display: inline-block;
  box-sizing: border-box;

  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.retryButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.removeButton {
  width: 20px;
  height: 20px;
  border-radius: 20px;

  flex-shrink: 0;

  line-height: 0;

  position: absolute;
  top: -5px;
  right: -5px;
  background: black;
  border: none;
  color: white;
  cursor: pointer;

  z-index: 3;
}
