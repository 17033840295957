.review_switch {
  display: flex;
  flex-direction: row;
  gap: 5px;

  margin-top: 20px;

  .label {
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    margin-left: 5px;
  }
}
